<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('companies.contactDetails') }}</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="position"
					:label="$t('companies.position')"
					:rules="rules.position"
					outlined
					prepend-inner-icon="mdi-briefcase-account"
					rounded
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
				/>
				<v-text-field
					v-model="phone"
					:label="$t('companies.phone')"
					:rules="rules.phone"
					outlined
					prepend-inner-icon="mdi-phone"
					rounded
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
				/>
				<v-text-field
					v-model="email"
					:label="$t('auth.email')"
					:rules="rules.email"
					outlined
					prepend-inner-icon="mdi-email-outline"
					rounded
					hide-details="auto"
					class="pb-3"
					style="max-width:400px;"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" rounded @click="callEditAccountContact()">
				{{ $t('settings.update') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'SettingsChangeAccountContactForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			valid: false,
			position: '',
			phone: '',
			email: '',
			rules: {
				position: [(v) => 0 < v.length <= this.positionMaxLength || this.$t('rules.length', { length: this.positionMaxLength })],
				phone: [(v) => 0 < v.length <= this.phoneMaxLength || this.$t('rules.length', { length: this.phoneMaxLength })],
				email: [(v) => 0 < v.length <= this.emailMaxLength || this.$t('rules.length', { length: this.emailMaxLength })]
			}
		}
	},
	computed: {
		positionMaxLength() {
			return 128
		},
		phoneMaxLength() {
			return 20
		},
		emailMaxLength() {
			return 255
		},
		...mapGetters({
			currentUser: 'user/currentUser'
		})
	},
	created() {
		this.position = this.currentUser.contact.position
		this.phone = this.currentUser.contact.phone
		this.email = this.currentUser.contact.email
	},
	methods: {
		callEditAccountContact() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.editAccountContact({ position: this.position, phone: this.phone, email: this.email })
		},
		...mapActions('settings', ['editAccountContact'])
	}
}
</script>
